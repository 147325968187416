import { DateTime } from "luxon";
import { DataTypes } from "variables/constants";
import { Sorts, Operators } from "variables/constants";
import { InvalidKeyError, UnsupportedOperatorError } from "variables/errors";

const initState = {};

export function getCollection(state, collection) {
  return state[collection] || { records: [] };
}

export function getSearch(state, collection) {
  return getCollection(state, collection).search || { filter: [], sort: [] };
}

export function getRow(collection, index) {
  return collection.records[index] || { fields: {} };
}

export function getRowByKey(collection, key) {
  return collection.records.find((row) => row.key === key) || { fields: {} };
}

export function checkRowByKey(collection, key) {
  return !!collection.records.find((row) => row.key === key);
}

export function getField(row, column) {
  return row.fields[column];
}

export function getFieldConfig(config, column) {
  return (config && config.fields && config.fields[column]) || {};
}

export function getCollectionRow(state, collection, index) {
  return getRow(getCollection(state, collection), index);
}

export function getCollectionRowByKey(state, collection, key) {
  return getRowByKey(getCollection(state, collection), key);
}

export function checkCollectionRowByKey(state, collection, key) {
  return checkRowByKey(getCollection(state, collection), key);
}

export function getCollectionRowField(state, collection, index, column) {
  return getField(getCollectionRow(state, collection, index), column);
}

export function getCollectionRowByKeyField(state, collection, index, column) {
  return getField(getCollectionRowByKey(state, collection, index), column);
}

function bindReducer(reducer, accessor) {
  return (state, ...rest) => accessor(state[reducer], ...rest);
}

function bindReducerAndCollection(reducer, collection, accessor) {
  return (state, ...rest) => accessor(state[reducer], collection, ...rest);
}

function bindReducerCollectionAndIndex(reducer, collection, index, accessor) {
  return (state, ...rest) =>
    accessor(state[reducer], collection, index, ...rest);
}

function bindReducerCollectionAndKey(reducer, collection, key, accessor) {
  return (state, ...rest) => accessor(state[reducer], collection, key, ...rest);
}

function bindConfig(config, accessor) {
  return (...rest) => accessor(config, ...rest);
}

export function getAccessors(reducer) {
  return {
    getCollection: bindReducer(reducer, getCollection),
    getCollectionRow: bindReducer(reducer, getCollectionRow),
    getCollectionRowField: bindReducer(reducer, getCollectionRowField),
    getCollectionRowByKey: bindReducer(reducer, getCollectionRowByKey),
    getCollectionRowByKeyField: bindReducer(
      reducer,
      getCollectionRowByKeyField
    ),
    getSearch: bindReducer(reducer, getSearch),
  };
}

export function getAccessorsForCollection(config, reducer, collection) {
  return {
    get: bindReducerAndCollection(reducer, collection, getCollection),
    getRow: bindReducerAndCollection(reducer, collection, getCollectionRow),
    getRowField: bindReducerAndCollection(
      reducer,
      collection,
      getCollectionRowField
    ),
    getRowByKey: bindReducerAndCollection(
      reducer,
      collection,
      getCollectionRowByKey
    ),
    getRowByKeyField: bindReducerAndCollection(
      reducer,
      collection,
      getCollectionRowByKeyField
    ),
    getSearch: bindReducerAndCollection(reducer, collection, getSearch),
    getConfig: () => config,
    getFieldConfig: bindConfig(config, getFieldConfig),
  };
}

export function getAccessorsForCollectionRow(
  config,
  reducer,
  collection,
  index
) {
  return {
    get: bindReducerCollectionAndIndex(
      reducer,
      collection,
      index,
      getCollectionRow
    ),
    getField: bindReducerCollectionAndIndex(
      reducer,
      collection,
      index,
      getCollectionRowField
    ),
    getConfig: () => config,
    getFieldConfig: bindConfig(config, getFieldConfig),
  };
}

export function getAccessorsForCollectionRowByKey(
  config,
  reducer,
  collection,
  key
) {
  return {
    get: bindReducerCollectionAndKey(
      reducer,
      collection,
      key,
      getCollectionRowByKey
    ),
    getField: bindReducerCollectionAndKey(
      reducer,
      collection,
      key,
      getCollectionRowByKeyField
    ),
    getConfig: () => config,
    getFieldConfig: bindConfig(config, getFieldConfig),
  };
}

function calculationHandler(calculator) {
  return {
    get: (target, prop) => {
      return calculator(prop, (col) => target[col]);
    },
  };
}

function rowHandler(calculator) {
  return {
    get: (target, prop) => {
      return prop === "fields"
        ? new Proxy(target.fields, calculationHandler(calculator))
        : target[prop];
    },
  };
}

export function addCalculatedFields(accessors, calculator) {
  return {
    ...accessors,
    getRowField: (state, index, column) =>
      calculator(column, (c) => accessors.getRowField(state, index, c)),
    getRowByKeyField: (state, key, column) =>
      calculator(column, (c) => accessors.getRowByKeyField(state, key, c)),
    getRow: (state, index) =>
      new Proxy(accessors.getRow(state, index), rowHandler(calculator)),
  };
}

/* eslint-disable eqeqeq */
function matchField(a, operator, b) {
  switch (operator) {
    case Operators.greaterThanOrEqualTo:
      return a >= b;
    case Operators.lessThanOrEqualTo:
      return a <= b;
    case Operators.lessThan:
      return a < b;
    case Operators.greaterThan:
      return a > b;
    case Operators.equalTo:
      return a == b;
    case Operators.notEqualTo:
      return a != b;
    case Operators.startsWith:
      return String(a).toLowerCase().startsWith(String(b).toLowerCase());
    default:
      throw new UnsupportedOperatorError();
  }
}
/* eslint-enable eqeqeq */

function applyConfig(config) {
  function getCollection(collection, state) {
    const collectionConfig = config[collection];
    const collectionState = state[collection] || {
      allRecords: [],
      search: { filter: [], sort: [] },
    };

    function updateAllRecords(updater) {
      const allRecords = updater(collectionState.allRecords);
      const { sort = [], filter = [] } = collectionState.search;
      return {
        ...collectionState,
        allRecords,
        records:
          filter.length === 0 && sort.length === 0
            ? allRecords
            : allRecords
                .filter((row) => match(filter, row.fields))
                .sort((a, b) => compare(sort, a.fields, b.fields)),
      };
    }

    function addRecord({ key, fields }) {
      return updateAllRecords((allRecords) => {
        if (allRecords.find((record) => record.key === key))
          throw new InvalidKeyError(key);
        return [...allRecords, { key, fields }];
      });
    }

    function deleteRecord(key) {
      return updateAllRecords((allRecords) =>
        allRecords.filter((record) => record.key !== key)
      );
    }

    function updateRecord({ key, fields }) {
      return updateAllRecords((allRecords) => {
        let updateSuccess = false;
        allRecords = allRecords.map((record) => {
          if (record.key === key) {
            updateSuccess = true;
            return { key, fields: { ...record.fields, ...fields } };
          } else {
            return record;
          }
        });
        if (!updateSuccess) throw new InvalidKeyError(key);
        return allRecords;
      });
    }

    function upsertRecord({ key, fields }) {
      return updateAllRecords((allRecords) => {
        let updateSuccess = false;
        allRecords = allRecords.map((record) => {
          if (record.key === key) {
            updateSuccess = true;
            return { key, fields: { ...record.fields, ...fields } };
          } else {
            return record;
          }
        });
        if (!updateSuccess) {
          allRecords = [...allRecords, { key, fields }];
        }
        return allRecords;
      });
    }

    function matchFilterElement(filterElement, fields) {
      if (typeof filterElement === "string") {
        const searchFields =
          collectionConfig.textSearchFields ||
          Object.getOwnPropertyNames(fields);
        return searchFields.reduce(
          (accumulator, field) =>
            accumulator ||
            matchField(fields[field], Operators.startsWith, filterElement),
          false
        );
      } else {
        return Object.entries(filterElement).reduce(
          (accumulator, [field, { operator, value }]) =>
            accumulator &&
            matchField(fields[field], operator || Operators.startsWith, value),
          true
        );
      }
    }

    function match(filter, fields) {
      return filter.reduce(
        (accumulator, element) =>
          accumulator && matchFilterElement(element, fields),
        true
      );
    }

    function compareField(field, a, b) {
      const fieldConfig =
        collectionConfig.fields && collectionConfig.fields[field];
      const type = fieldConfig && fieldConfig.type;
      switch (type) {
        case DataTypes.DATETIME:
          if (DateTime.isDateTime(a) && DateTime.isDateTime(b)) {
            return a.toMillis() - b.toMillis();
          } else {
            if (DateTime.isDateTime(a)) return 1;
            if (DateTime.isDateTime(b)) return -1;
            break;
          }
        case DataTypes.NUMBER:
          if (isNaN(a) || isNaN(b)) {
            if (isNaN(b)) return 1;
            if (isNaN(a)) return -1;
            break;
          } else {
            return Number(a) - Number(b);
          }
        default:
          // javascript compare-however
          break;
      }
      let sa = String(a),
        sb = String(b);
      if (sa === sb) return 0;
      if (sa > sb) return 1;
      return -1;
    }

    function compare(sort, fieldsA, fieldsB) {
      let result = 0;
      for (let i = 0; i < sort.length && result === 0; i++) {
        const { field, order } = sort[i];
        result =
          compareField(field, fieldsA[field], fieldsB[field]) *
          (order === Sorts.ASCENDING ? 1 : -1);
      }
      return result;
    }

    function applySearch({ filter = [], sort = [] }, values) {
      const allRecords = values || collectionState.allRecords;
      if (sessionStorage.getItem("filter") && !filter.length) {
        filter = sessionStorage.getItem("filter").split(",");
      }
      sessionStorage.setItem("filter", filter);
      return {
        ...collectionState,
        search: { filter, sort },
        allRecords,
        records:
          filter.length === 0 && sort.length === 0
            ? allRecords
            : allRecords
                .filter((row) => match(filter, row.fields))
                .sort((a, b) => compare(sort, a.fields, b.fields)),
      };
    }

    function applyLiveFilter(liveFilter, values) {
      const allRecords = values || collectionState.allRecords;
      const { filter = [] } = collectionState.search;
      return {
        ...collectionState,
        allRecords,
        records:
          filter.length === 0 && liveFilter.length === 0
            ? allRecords
            : allRecords.filter((row) =>
                match([...filter, liveFilter], row.fields)
              ),
      };
    }

    function updateState(state, update) {
      return { ...state, [collection]: update };
    }

    function setError(error) {
      return {
        ...collectionState,
        error,
      };
    }

    return {
      updateState,
      applySearch,
      applyLiveFilter,
      addRecord,
      deleteRecord,
      updateRecord,
      upsertRecord,
      setError,
    };
  }

  function updateKey({ key, fields }) {
    if (key === undefined) key = fields[config.key];
    return { key, fields };
  }

  function reducer(state = initState, action) {
    const collection = getCollection(action.collection, state);

    switch (action.type) {
      case "RECORDSET_INITIALIZE":
        return collection.updateState(
          state,
          collection.applySearch({}, action.values)
        );
      case "RECORDSET_SEARCH":
        return collection.updateState(
          state,
          collection.applySearch(action.search, action.values)
        );
      case "RECORDSET_LIVE_FILTER":
        return collection.updateState(
          state,
          collection.applyLiveFilter(action.filter, action.values)
        );
      case "RECORDSET_SET_ERROR":
        return collection.updateState(state, collection.setError(action.error));
      case "RECORDSET_ADD_RECORD":
        return collection.updateState(
          state,
          collection.addRecord(updateKey(action.record))
        );
      case "RECORDSET_DELETE_RECORD":
        return collection.updateState(
          state,
          collection.deleteRecord(action.key)
        );
      case "RECORDSET_UPDATE_RECORD":
        return collection.updateState(
          state,
          collection.updateRecord(updateKey(action.record))
        );
      case "RECORDSET_UPSERT_RECORD":
        return collection.updateState(
          state,
          collection.upsertRecord(updateKey(action.record))
        );
      default:
        return state;
    }
  }

  return {
    reducer,
  };
}

export default (config) => applyConfig(config).reducer;
