import React, { useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

// mui core
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as Upload } from "assets/img/tuzo/icons/Upload.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import avatar from "assets/img/default-avatar.png";

export function UserInfo(props) {
  const {
    progress,
    uploading,
    docFile,
    deleteDoc,
    handleDocUpload,
    classes,
    history,
    profilePic,
    imageFile,
    username,
    setUsername,
    apiUsername,
    setApiUsername,
    apiPassword,
    setApiPassword,
    firstNameState,
    setFirstNameState,
    lastNameState,
    setLastNameState,
    companyState,
    setCompanyState,
    ein,
    setEin,
    notes,
    setNotes,
    processorName,
    setProcessorName,
    processorPhone,
    setProcessorPhone,
    processorEmail,
    setProcessorEmail,
    processorMid,
    setProcessorMid,
    emailState,
    setEmailState,
    addressState,
    setAddressState,
    userBasisPts,
    setUserBasisPts,
    signUpBonus,
    setSignUpBonus,
    merchantId,
    setMerchantId,
    handleSelect,
    processorArr,
    errors,
    type,
    setType,
    typeArr,
    resetState,
    userApi,
    accountId,
    setAccountId,
  } = props;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card
            className={classes.profileCardStyling}
            style={{ overflow: "visible", width: "100%" }}
          >
            <Typography
              variant="h2"
              className={classes.cardDesc}
              style={{ marginTop: "0px" }}
            >
              *Required
            </Typography>
            <GridContainer>
              {type === "Merchant" ? (
                <>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          User Type
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <Select
                          style={{ paddingTop: "5px" }}
                          className={classes.selectMenuSortProfile}
                          value={type}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setType(e.target.value);
                            resetState();
                          }}
                        >
                          {typeArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.email || errors.emailTypo
                              ? { marginTop: "30px" }
                              : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          Merchant Email*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={emailState}
                            onChange={(e) => {
                              setEmailState(e.target.value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.email ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : errors.emailTypo ? (
                            <span className={classes.errText}>
                              Please double check your email
                            </span>
                          ) : null}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={errors.points ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Basis Points*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            type="number"
                            className={classes.userInfoTextField}
                            value={userBasisPts}
                            onChange={(e) => setUserBasisPts(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                              inputProps: {
                                min: 0,
                                max: 50,
                              },
                            }}
                          />
                          {errors.points ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={errors.id ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          Merchant ID*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={merchantId}
                            onChange={(e) => setMerchantId(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.id ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Sign-Up Bonus Points
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          type="number"
                          className={classes.userInfoTextField}
                          value={signUpBonus}
                          onChange={(e) => setSignUpBonus(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              input: classes.input,
                            },
                            inputProps: {
                              min: 0,
                              max: 50,
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={errors.username ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          Merchant Username*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.username ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Processor*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <Select
                          className={classes.selectMenuSortProfile}
                          value={processorName}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            if (userApi) {
                              setProcessorName(e.target.value.apiType);
                              setApiUsername(e.target.value.apiKey);
                              setApiPassword(e.target.value.apiSecret);
                            } else {
                              setProcessorName(e.target.value.apiType);
                              setApiUsername("");
                              setApiPassword("");
                            }
                          }}
                          renderValue={(value) => {
                            return value;
                          }}
                        >
                          {userApi.map((o, i) => {
                            return (
                              <MenuItem
                                key={o.id}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o.apiType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  {processorName.includes("Maverick") ? (
                    <>
                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={
                                errors.firstName ? { marginTop: "30px" } : {}
                              }
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant First Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={firstNameState}
                                onChange={(e) =>
                                  setFirstNameState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.firstName ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={
                                errors.accountId ? { marginTop: "30px" } : {}
                              }
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Account ID*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={accountId}
                                onChange={(e) => setAccountId(e.target.value)}
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.accountId ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={
                                errors.lastName ? { marginTop: "30px" } : {}
                              }
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Last Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={lastNameState}
                                onChange={(e) =>
                                  setLastNameState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.lastName ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Processor Contact Phone
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={processorPhone}
                              onChange={(e) =>
                                setProcessorPhone(e.target.value)
                              }
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={errors.dba ? { marginTop: "30px" } : {}}
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Company Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={companyState}
                                onChange={(e) =>
                                  setCompanyState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.dba ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Processor Contact Email
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={processorEmail}
                              onChange={(e) =>
                                setProcessorEmail(e.target.value)
                              }
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant EIN
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={ein}
                              onChange={(e) => setEin(e.target.value)}
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Comments/Notes
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextareaAutosize
                              style={{
                                height: "66px",
                                color: "#1A405F",
                                lineHeight: "24px",
                              }}
                              className={classes.userInfoTextField}
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Address
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <PlacesAutocomplete
                              value={addressState}
                              onChange={(e) => setAddressState(e)}
                              onSelect={(e) =>
                                handleSelect(e, "Search the address..")
                              }
                              googleCallbackName={"initOne"}
                              searchOptions={{
                                componentRestrictions: { country: "us" },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <FormControl style={{ width: "100%" }}>
                                    <TextField
                                      style={{ border: "none" }}
                                      value={addressState}
                                      InputProps={{
                                        autocomplete: "new-password",
                                        form: {
                                          autocomplete: "off",
                                        },
                                        classes: {
                                          input: classes.inputNoPadding,
                                          root: classes.userInfoTextField,
                                        },
                                      }}
                                      // className={classes.userInfoTextField}
                                      variant="outlined"
                                      {...getInputProps({
                                        placeholder: "Search the address..",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </FormControl>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={
                                errors.firstName ? { marginTop: "30px" } : {}
                              }
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant First Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={firstNameState}
                                onChange={(e) =>
                                  setFirstNameState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.firstName ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Processor Contact Phone
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={processorPhone}
                              onChange={(e) =>
                                setProcessorPhone(e.target.value)
                              }
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={
                                errors.lastName ? { marginTop: "30px" } : {}
                              }
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Last Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={lastNameState}
                                onChange={(e) =>
                                  setLastNameState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.lastName ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Processor Contact Email
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={processorEmail}
                              onChange={(e) =>
                                setProcessorEmail(e.target.value)
                              }
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              style={errors.dba ? { marginTop: "30px" } : {}}
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Company Name*
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <div>
                              <TextField
                                className={classes.userInfoTextField}
                                value={companyState}
                                onChange={(e) =>
                                  setCompanyState(e.target.value)
                                }
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                              />
                              {errors.dba ? (
                                <span className={classes.errText}>
                                  Please fill out the field above
                                </span>
                              ) : null}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Comments/Notes
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextareaAutosize
                              style={{
                                height: "66px",
                                color: "#1A405F",
                                lineHeight: "24px",
                              }}
                              className={classes.userInfoTextField}
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant EIN
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <TextField
                              className={classes.userInfoTextField}
                              value={ein}
                              onChange={(e) => setEin(e.target.value)}
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={6} />

                      <GridItem xs={6} style={{ marginTop: "-42px" }}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <Typography
                              variant="h2"
                              className={classes.cardDesc}
                            >
                              Merchant Address
                            </Typography>
                          </GridItem>

                          <GridItem xs={6}>
                            <PlacesAutocomplete
                              value={addressState}
                              onChange={(e) => setAddressState(e)}
                              onSelect={(e) =>
                                handleSelect(e, "Search the address..")
                              }
                              googleCallbackName={"initOne"}
                              searchOptions={{
                                componentRestrictions: { country: "us" },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <FormControl style={{ width: "100%" }}>
                                    <TextField
                                      style={{ border: "none" }}
                                      value={addressState}
                                      InputProps={{
                                        autocomplete: "new-password",
                                        form: {
                                          autocomplete: "off",
                                        },
                                        classes: {
                                          input: classes.inputNoPadding,
                                          root: classes.userInfoTextField,
                                        },
                                      }}
                                      // className={classes.userInfoTextField}
                                      variant="outlined"
                                      {...getInputProps({
                                        placeholder: "Search the address..",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </FormControl>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </>
                  )}
                </>
              ) : type === "Staff" ? (
                <>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          User Type
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <Select
                          style={{ paddingTop: "5px" }}
                          className={classes.selectMenuSortProfile}
                          value={type}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          {typeArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.email || errors.emailTypo
                              ? { marginTop: "30px" }
                              : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Email*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={emailState}
                            onChange={(e) => {
                              setEmailState(e.target.value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.email ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : errors.emailTypo ? (
                            <span className={classes.errText}>
                              Please double check your email
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.username ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Username*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.username ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.firstName ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User First Name*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={firstNameState}
                            onChange={(e) => setFirstNameState(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.firstName ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.lastName ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Last Name*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={lastNameState}
                            onChange={(e) => setLastNameState(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.lastName ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Comments/Notes
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextareaAutosize
                          style={{
                            height: "66px",
                            color: "#1A405F",
                            lineHeight: "24px",
                          }}
                          className={classes.userInfoTextField}
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              input: classes.input,
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem xs={6}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          User Type
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <Select
                          style={{ paddingTop: "5px" }}
                          className={classes.selectMenuSortProfile}
                          value={type}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          {typeArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.email || errors.emailTypo
                              ? { marginTop: "30px" }
                              : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Email*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={emailState}
                            onChange={(e) => {
                              setEmailState(e.target.value);
                            }}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.email ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : errors.emailTypo ? (
                            <span className={classes.errText}>
                              Please double check your email
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.username ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Username*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.username ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.firstName ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User First Name*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={firstNameState}
                            onChange={(e) => setFirstNameState(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.firstName ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.lastName ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Last Name*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={lastNameState}
                            onChange={(e) => setLastNameState(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.lastName ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography
                          style={errors.dba ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Company Name*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <div>
                          <TextField
                            className={classes.userInfoTextField}
                            value={companyState}
                            onChange={(e) => setCompanyState(e.target.value)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                input: classes.input,
                              },
                            }}
                          />
                          {errors.dba ? (
                            <span className={classes.errText}>
                              Please fill out the field above
                            </span>
                          ) : null}
                        </div>
                      </GridItem>

                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Comments/Notes
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextareaAutosize
                          style={{
                            height: "66px",
                            color: "#1A405F",
                            lineHeight: "24px",
                          }}
                          className={classes.userInfoTextField}
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              input: classes.input,
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              )}
            </GridContainer>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card
            className={classes.profileCardStyling}
            style={{ marginTop: "20px", height: "auto" }}
          >
            <GridContainer>
              <GridItem xs={5}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Upload Documents
                </Typography>
              </GridItem>
              <GridItem xs={7}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Documents
                </Typography>
                <GridItem
                  xs={12}
                  style={{
                    borderBottom: "1px solid #56CA93",
                    margin: "6px 5px",
                  }}
                />
              </GridItem>

              <GridItem xs={5}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    style={{ marginTop: "20px", textAlign: "center" }}
                  >
                    <div className={classes.uploadDocument}>
                      <GridItem xs={12}>
                        <Typography
                          variant="h2"
                          className={classes.cardDesc}
                          style={{ marginTop: "20px" }}
                        >
                          Upload your Document (optional)
                        </Typography>

                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          Supported File Types: PDF, JPEG, PNG, EPS, DOCX
                        </Typography>
                        {uploading ? (
                          <GridContainer
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "15px",
                            }}
                          >
                            <GridItem xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ width: "100%", mr: 1 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={Math.round(progress) * 100}
                                  />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                  <h6
                                    style={{
                                      color: "#1A405F",
                                      fontSize: "22px",
                                      fontFamily: "Josefin Sans",
                                    }}
                                  >
                                    {Math.round(progress)}%
                                  </h6>
                                </Box>
                              </Box>
                            </GridItem>
                          </GridContainer>
                        ) : null}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className={classes.uploadButton}
                            component="label"
                          >
                            <Upload style={{ marginRight: "10px" }} /> Upload
                            <input
                              type="file"
                              hidden
                              onChange={(event) => {
                                handleDocUpload(event, "modal");
                              }}
                            />
                          </Button>
                        </div>
                      </GridItem>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={7}>
                <GridContainer>
                  {docFile.map((file, index) => (
                    <GridItem xs={12} key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h2"
                          className={classes.contractDesc}
                          style={{ marginRight: "10px" }}
                        >
                          {file.fileName}
                        </Typography>
                        <GridItem
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            className={classes.uploadButton}
                            style={{ background: "#56CA93", width: 12 }}
                            onClick={() =>
                              deleteDoc(file.fileName, file.filePath)
                            }
                          >
                            <TrashIcon />
                          </Button>
                        </GridItem>
                      </div>
                    </GridItem>
                  ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userApi: state.auth.user.apiInfo,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
  )(UserInfo)
);
