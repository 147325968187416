import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { db } from 'firebase/client';

// mui core
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";

//icons
import { Add, CheckCircle, Close, } from '@material-ui/icons';
import { ReactComponent as TestIcon } from "assets/img/tuzo/icons/TestIcon.svg";
import { sendTestIrisConnection, sendTestAccessOneConnection } from 'firebase/client';
import { sendTestMavConnection } from 'firebase/client';

export function GatewayPage(props) {
    const {
      classes,
      gateway,
      setGateway,
      globalEdit,
      apiInfo,
      setApiInfo,
      docId,
      loader,
      userData
    } = props;

  const [testModal, setTestModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [codeResult, setCodeResult] = useState("");
  const [updateDb, setUpdateDb] = useState(false);

  const deleteCredentials = (index) => {
      let newArrObj = [...apiInfo]
      newArrObj.splice(index,1)

      setApiInfo(newArrObj)
      setGateway(newArrObj[0].apiType)
  }

    const setCredentials = (apiName, key, password, index) => {
      setApiInfo((prev) => {
        return prev.map((item) => {
            if (item.id === index) {
              return { ...item, default: true }
            } else {
                return { ...item, default: false }
            }
        })
      })
      db.collection('merchants-live').where("merchantNumber", "==", userData.merchantId).get()
      .then((snapshots) => {
          if(!snapshots.empty){
              snapshots.forEach((s) => {
                  let sId = s.id
                  db.collection('merchants-live').doc(sId).update({
                      ApiType: apiName,
                      username: key,
                      password: password
                  })
              })
          }
      })
      .then(() => {
        setUpdateDb(true)
      })
    }

    const addCredentials = () => {
        let idCount = ""
        setApiInfo((prev) => {
            let arrLen = prev.length
            idCount = prev[arrLen-1].id + 1
        })
        const newObj = {
            apiType: gateway + " " + idCount,
            apiKey: "",
            apiSecret: "",
            status: "Unused",
            id: idCount
        }
        setApiInfo([...apiInfo, newObj])
        setGateway(newObj.apiType)
    }

    const testApi = async (apiName, key, password, index) => {
        setTestModal(true)
        let obj = {
            apiUsername: key,
            apiPassword: password,
            apiUrl: userData.companyName.replace(/ /g, '').toLowerCase()
        }
        if (apiName.includes("IRIS")) {
            return sendTestIrisConnection(obj)
                .then(async (data) => {
                    if (data.data === 200) {
                        setCodeResult(data.data)
                        return setApiInfo((prev) => {
                            return prev.map((item) => {
                                if (item.id === index) {
                                    return { ...item, status: "Active" }
                                } else {
                                    return item
                                }
                            })
                        })
                    } else {
                        setCodeResult(data.data)
                        return setApiInfo((prev) => {
                            return prev.map((item) => {
                                if (item.id === index) {
                                    return { ...item, status: "Pending" }
                                } else {
                                    return item
                                }
                            })
                        })
                    }
                })
                .then(() => {
                    setUpdateDb(true)
                    setTestModal(false)
                    setResultModal(true)

                })
        }
        if(apiName.includes("Fiserv AccessOne")){
            sendTestAccessOneConnection(obj)
            .then(async (data) => {
                if (data.data === 200) {
                    setCodeResult(data.data)
                    return setApiInfo((prev) => {
                        return prev.map((item) => {
                            if (item.id === index) {
                                return { ...item, status: "Active" }
                            } else {
                                return item
                            }
                        })
                    })
                } else {
                    setCodeResult(data.data)
                    return setApiInfo((prev) => {
                        return prev.map((item) => {
                            if (item.id === index) {
                                return { ...item, status: "Pending" }
                            } else {
                                return item
                            }
                        })
                    })
                }
            })
            .then(() => {
                setUpdateDb(true)
                setTestModal(false)
                setResultModal(true)

            })
        }
        if (apiName.includes("Maverick")) {
            return sendTestMavConnection(obj)
                .then(async (data) => {
                    if (data.data === 200) {
                        setCodeResult(data.data)
                        return setApiInfo((prev) => {
                            return prev.map((item) => {
                                if (item.id === index) {
                                    return { ...item, status: "Active" }
                                } else {
                                    return item
                                }
                            })
                        })
                    } else {
                        setCodeResult(data.data)
                        return setApiInfo((prev) => {
                            return prev.map((item) => {
                                if (item.id === index) {
                                    return { ...item, status: "Pending" }
                                } else {
                                    return item
                                }
                            })
                        })
                    }
                })
                .then(() => {
                    setUpdateDb(true)
                    setTestModal(false)
                    setResultModal(true)
                })
        }
    }

    const updateApiDb = () => {
        let newInfo = { apiInfo }
        db.collection('users')
        .doc(docId)
        .update(newInfo)
    }

  useEffect(() => {
    if (updateDb === true) {
      updateApiDb();
    }
  }, [updateDb]);

  const maskDetails = (strKey) => {
    const mask = strKey
      .toLowerCase()
      .substring(0, strKey.length - 4)
      .replace(/[A-z0-9\W]/g, "*");
    const subStr = strKey.substring(strKey.length - 4, strKey.length);

    if (mask.length <= 3) {
      let newMask = "*******" + mask;
      return newMask.substring(0, 4) + subStr;
    } else {
      return mask.substring(0, 4) + subStr;
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: "15px" }}>
          <Card className={classes.profileCardStyling}>
            <GridContainer>
              <GridItem xs={6}>
                <div className={classes.divHover}>
                  <Tooltip
                    id="tooltip-top"
                    title={`This information is accessible through your processing gateway. 
                                    Common locations to find it are highlighted in our blog article.`}
                    placement="top"
                    classes={{ tooltip: classes.tooltipPts }}
                    style={{ fontSize: "13px !important" }}
                  >
                    <Typography variant="h2" className={classes.cardHeader}>
                      Processing Gateway
                    </Typography>
                  </Tooltip>
                </div>
              </GridItem>

              <GridItem xs={5} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Select
                      style={{ width: "85%", height: "30px", marginTop: "-5px" }}
                      className={classes.selectMenuSort}
                      value={gateway}
                      disableUnderline
                      classes={{
                          root: classes.selectText,
                      }}
                      onChange={(e) => {
                          setGateway(e.target.value.apiType)
                      }}
                      displayEmpty
                      renderValue={(value) => {
                          if (gateway === "") {
                              return <p className={classes.selectPlaceHolder}>Gateways</p>;
                          } else {
                              return (
                                  <div style={{ display: "flex", gap: 1, alignItems: "center" }}>
                                      {apiInfo.map((data, index) => (
                                          data.apiType !== gateway ? null
                                              :
                                              <SvgIcon>
                                                  <CheckCircle
                                                      style={
                                                          data.status && data.status === "Active" ?
                                                              {
                                                                  color: "#56CA93"
                                                              }
                                                              :
                                                              data.status && data.status === "Pending" ?
                                                                  {
                                                                      color: "#FECD2C"
                                                                  }
                                                                  :
                                                                  {
                                                                      color: "#DADADA"
                                                                  }

                                                      }
                                                  />
                                              </SvgIcon>
                                      ))}
                                      {value}
                                  </div>
                              )
                          }
                      }}
                  >
                      <MenuItem
                          disabled
                          value=""
                          classes={{
                              root: classes.selectPlaceHolder,
                          }}
                      >
                          <p
                          >
                              Gateways
                          </p>
                      </MenuItem>
                      {apiInfo.map((o, i) => {
                          return (
                              <MenuItem
                                  key={o.id}
                                  value={o}
                                  classes={{
                                      root: classes.selectMenuItem,
                                  }}
                              >
                                  <CheckCircle
                                      style={
                                          apiInfo[i] === undefined ?
                                              {
                                                  color: "#DADADA"

                                              }
                                              :
                                              apiInfo[i].status && apiInfo[i].status === "Active" ?
                                                  {
                                                      color: "#56CA93"
                                                  }
                                                  :
                                                  apiInfo[i].status && apiInfo[i].status === "Pending" ?
                                                      {
                                                          color: "#FECD2C"
                                                      }
                                                      :
                                                      {
                                                          color: "#DADADA"
                                                      }

                                      }
                                  />
                                  {o.apiType}
                              </MenuItem>
                          );
                      })}
                  </Select>
              </GridItem>

              {apiInfo.map((data, index) => (

                  data.apiType === gateway && index === data.id?
                      <>
                          <GridItem xs={12} key={index}>
                              <GridContainer>
                                  <GridItem xs={6}>
                                      <Typography variant="h2" className={classes.cardDesc}>
                                          API Key/Username
                                      </Typography>
                                  </GridItem>

                          <GridItem xs={6}>
                            {globalEdit ? (
                              <>
                                <TextField
                                  className={classes.userInfoTextField}
                                  value={data.apiKey}
                                  onChange={(e) => {
                                    e.persist();
                                    setApiInfo((prev) => {
                                      return prev.map((item) => {
                                        if (item.id === index) {
                                          return {
                                            ...item,
                                            apiKey: e.target.value,
                                          };
                                        } else {
                                          return item;
                                        }
                                      });
                                    });
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                      adornedEnd: classes.adournedEnd,
                                      input: classes.input,
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant="h2"
                                  className={classes.cardDescLessWeight}
                                >
                                {
                                  data.apiKey && data.default? maskDetails(data.apiKey) + " (Default)"
                                  : 
                                  data.apiKey && !data.default? maskDetails(data.apiKey)
                                  :
                                  "N/A"
                                }
                                </Typography>
                              </>
                            )}
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={6}>
                          <Typography variant="h2" className={classes.cardDesc}>
                            API Secret
                          </Typography>
                        </GridItem>

                        <GridItem xs={6}>
                            {globalEdit ?
                                <>
                                    <TextField
                                        className={classes.userInfoTextField}
                                        value={data.apiSecret}
                                        onChange={(e) => {
                                            e.persist()
                                            setApiInfo((prev) => {
                                                return prev.map((item) => {
                                                    if (item.id === index) {
                                                        return { ...item, apiSecret: e.target.value }
                                                    } else {
                                                        return item
                                                    }
                                                })
                                            })
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                adornedEnd: classes.adournedEnd,
                                                input: classes.input
                                            },
                                        }}
                                    />
                                </>
                                :
                                <>
                                    <Typography variant="h2" className={classes.cardDescLessWeight}>
                                        {data.apiSecret ? maskDetails(data.apiSecret) : "N/A"}
                                    </Typography>
                                </>
                            }
                        </GridItem>
                  </GridContainer>
              </GridItem>

                                        {!globalEdit && userData.userType === "IsoAdmin"?
                                            <>
                                                <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        className={classes.uploadButton}
                                                        style={{ background: "#56CA93", width: "200px", marginRight:"0px" }}
                                                        onClick={() => testApi(data.apiType, data.apiKey, data.apiSecret, index)}
                                                    >
                                                        <TestIcon style={{ marginRight: "10px" }} />  Test Connection
                                                    </Button>
                                                </GridItem>
                                            </>
                                            : 
                                            !globalEdit && userData.userType.includes("Merchant") ?
                                            <>
                                                <GridItem xs={6} style={{ display: "flex" }}>
                                                    <Button
                                                        className={classes.uploadButton}
                                                        style={{ background: "#1A405F", width: "200px", marginRight:"0px" }}
                                                        onClick={() => setCredentials(data.apiType, data.apiKey, data.apiSecret, index)}
                                                    >
                                                        <CheckCircle style={{ marginRight: "10px" }} />  Set Default
                                                    </Button>
                                                </GridItem>
                                                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        className={classes.uploadButton}
                                                        style={{ background: "#56CA93", width: "200px", marginRight:"0px" }}
                                                        onClick={() => testApi(data.apiType, data.apiKey, data.apiSecret, index)}
                                                    >
                                                        <TestIcon style={{ marginRight: "10px" }} />  Test Connection
                                                    </Button>
                                                </GridItem>
                                            </>
                                            :
                                            globalEdit?
                                            <>
                                            <GridItem xs={6} style={{ display: "flex" }}>
                                                    <Button
                                                        className={classes.uploadButton}
                                                        style={{ background: "#1A405F", width: "220px", marginRight:"0px" }}
                                                        onClick={() => deleteCredentials(index)}
                                                    >
                                                        <Close style={{ marginRight: "10px" }} />  Delete Credentials
                                                    </Button>
                                                </GridItem>
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                    :
                                    null
                            ))}
                            <GridItem xs={6} className={classes.noPaddingGridItem} style={{ display: "flex", justifyContent: "flex-end"  }}>
                                {
                                    globalEdit ?
                                    <>
                                        <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                className={classes.uploadButton}
                                                style={{ background: "#56CA93", width: "200px", marginRight:"0px" }}
                                                onClick={() => addCredentials()}
                                            >
                                                <Add style={{ marginRight: "10px" }} />  Add Credentials
                                            </Button>
                                        </GridItem>
                                    </>
                                :null}
                            </GridItem>
                        </GridContainer>
                    </Card>
                </GridItem>
            </GridContainer>

      <Dialog
        className={classes.requestModal}
        open={testModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography className={classes.cardHeader}>
                Testing API Connection...{" "}
              </Typography>
            </GridItem>

            <GridItem xs={12}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ width: "20%" }} src={loader} alt="..." />
              </div>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>

      <Dialog
        className={classes.requestModal}
        open={resultModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.confirmModalCard}>
          <GridContainer>
            <GridItem
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {codeResult === 200 ? (
                <Typography className={classes.cardHeader}>
                  Connection was a success!
                </Typography>
              ) : (
                <Typography className={classes.cardHeader}>
                  Connection failed! Please check your API Key/Secret.
                </Typography>
              )}
            </GridItem>

            <GridItem
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                className={classes.uploadButton}
                onClick={() => {
                  setResultModal(false);
                }}
              >
                <Close style={{ marginRight: "10px" }} /> Close
              </Button>
            </GridItem>
          </GridContainer>
        </Card>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
  )(GatewayPage)
);
