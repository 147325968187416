import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { connect } from "react-redux";

//styling
import Style from "assets/jss/material-dashboard-pro-react/views/wishlistStyle";
import withStyles from "@material-ui/core/styles/withStyles";

//icons
import { ReactComponent as NoRewardsIcon } from "assets/img/tuzo/icons/NoRewardsIcon.svg";
import { AiFillGift } from "react-icons/ai";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, Typography } from "@material-ui/core";
import Card from "components/Card/Card";
import { userTypes } from "variables/config";

export function NoWishlistPage(props) {
  const { classes, history, userType, userCompany, user } = props;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.noWishlistCard}>
            <NoRewardsIcon />

            <Typography className={classes.noWishlistTitle}>
              Oops you don't have any rewards saved in your Wish List yet
            </Typography>

            <Typography className={classes.noWishlistNotBold}>
              Click the heart icon on all of the rewards you want to save to see
              them here!
            </Typography>

            <Button
              className={classes.noWishlistButton}
              onClick={() => {
                if (user.IsoAdminCompanyName) {
                  history.push(
                    `/iso-merchant/${user.IsoAdminCompanyName.replace(
                      / /g,
                      ""
                    ).toLowerCase()}/${user.companyName
                      .replace(/ /g, "")
                      .toLowerCase()}/rewards`
                  );
                } else {
                  history.push(`/merchant/rewards`);
                }
              }}
            >
              <AiFillGift
                className={classes.icon}
                style={{ marginRight: "10px" }}
              />{" "}
              Browse All Rewards
            </Button>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userType: state.auth.user.userType,
    userCompany: state.auth.user.companyName,
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(NoWishlistPage)
);
