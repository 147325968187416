import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Preview from './Preview';
import { db } from 'firebase/client';
import allRewards from "assets/img/tuzo/allRewards.png"
import { CopyToClipboard } from 'react-copy-to-clipboard';

// styling 
import Style from "assets/jss/material-dashboard-pro-react/views/calculatorStyle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Button, Hidden, Snackbar, TextField, Typography } from '@material-ui/core';
import { ArrowBackIos, CheckCircle } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Alert from "@material-ui/lab/Alert";
// global function 
import { ptsFormula } from 'globalCalculationFunction/globalCalculationFunction';

// @material-ui/icons
import { ReactComponent as SaveIcon } from "assets/img/tuzo/icons/SaveIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Copy } from "assets/img/tuzo/icons/CopyIcon.svg";


export function Calculator(props) {
    const { classes, history, userLogo, userCompany, userId } = props;
    const [grossAmt, setGrossAmt] = useState("")
    const [basisPts, setBasisPts] = useState(20)
    const [edit, setEdit] = useState(false)
    const [points, setPoints] = useState("")
    const [goNext, setGoNext] = useState(false)
    const [error, setError] = useState({
        grossAmt: false,
        basisAmt: false
    })
    const [arr, setArr] = useState(false)
    const [copy, setCopy] = useState(false)
    const [timesRan, setTimesRan] = useState(1)
    let count = 1
    let oldPts = 0

    if (goNext) {
        return <Preview grossAmt={grossAmt} points={points} arr={arr} timesRan={timesRan} />
    }

    const formatInput = (value) => {
        let newValue = value.replace(/[^0-9\.]/g, '').replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        setGrossAmt(newValue)
    }

    const formatBasis = (value) => {
        let newValue = value.replace(/[^0-9\.]/g, '').replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        setBasisPts(newValue)
    }


    const queryPts = (pts) => {
        const items = []
        db
            .collection("rewards-list-test")
            .orderBy('salePricePoints', 'desc')
            .where("salePricePoints", "<=", pts)
            .get()
            .then((snapshots) => {
                if (count == 1 && snapshots.docs.length < 4) {
                    count = count + 1
                    setTimesRan((prev) => prev + 1)
                    queryPts(pts * 12)
                }
                else if (count > 1 && snapshots.docs.length < 4) {
                    count = count + 1
                    setTimesRan((prev) => prev + 1)
                    queryPts(pts * 3)
                }
                else {
                    snapshots.forEach((doc) => {
                        items.push(doc.data())
                    })
                    setArr(items)
                }
            })
            .then(() => {
                setGoNext(true)
            })
    }


    const ptsCalculator = () => {
        let replacedgrossAmt = grossAmt.replace(/,/g, '')
        if (!grossAmt || edit) {
            let newErrors = {
                basisAmt: false,
                grossAmt: false
            }
            if(!basisPts || edit){
                newErrors = { ...newErrors, basisAmt: true };
            }
            if(!grossAmt){
                newErrors = { ...newErrors, grossAmt: true };
            }
            setError({ ...newErrors });
        }
        else {
            let basisPtsFunction = basisPts ? basisPts : 20
            setError(false)
            const floorDollarPerBassisPts = ptsFormula(replacedgrossAmt, basisPtsFunction)
            setPoints(floorDollarPerBassisPts)
            queryPts(floorDollarPerBassisPts)
        }
    }

    const saveBasisPts = (e) => {
        e.preventDefault();
        db.collection('users').doc(userId).update({
            calculatorBasisPts: basisPts
        })
        .then(() => {
            setEdit(false)
        })
    }

    return (
        <div>
            <Hidden mdUp implementation='css'>
                <GridContainer style={{ marginTop: "60px" }}>
                    <GridItem xs={12} style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.headerTitle}>
                            View Calculator
                        </Typography>
                    </GridItem>

                    <GridItem xs={12} style={{ display: "flex", alignItems: "center", marginTop: "15px", marginBottom:"15px" }}>
                        {!edit ?
                            <>
                                <Button
                                    onClick={() => setEdit(true)}
                                    className={classes.editButton}
                                    style={{ marginLeft: "0" }}
                                >
                                    <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
                                </Button>
                            </>
                            :
                            <Button
                                onClick={(e) => saveBasisPts(e)}
                                className={classes.editButton}
                                style={{ background: "#1A405F", marginLeft: "0" }}
                            >
                                <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                            </Button>
                        }



                        <CopyToClipboard text={`https://tuzo.app/auth/${userCompany.replace(/ /g, '').toLowerCase()}/iso-calculator`}
                            onCopy={() => setCopy(true)}>
                            <Button
                                // onClick={() => setGlobalEdit(true)}
                                style={{ background: "#56CA93", width: "200px" }}
                                className={classes.editButton}
                            >
                                <Copy style={{ marginRight: "10px", marginTop: "-5px" }} /> Copy Public URL
                            </Button>
                        </CopyToClipboard>
                        <Snackbar
                            open={copy}
                            onClose={() => setCopy(false)}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            autoHideDuration={3000}
                            style={{ top: "80px" }}
                        >
                            <Alert
                                onClose={() => setCopy(false)}
                                severity={"success"}
                                className={classes.alert}
                            >
                                Link Copied
                            </Alert>
                        </Snackbar>
                    </GridItem>
                </GridContainer>
                <div className={classes.calculatorCardIsoAdminMobile} >
                    <div>
                        <Typography className={classes.calculatorTitleMobile}>
                            The Tuzo Rewards Calculator
                        </Typography>

                        <Typography className={classes.calculatorSubtitleMobile} style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                            Calculate how many rewards points you can earn!
                        </Typography>
                    </div>
                    <div>
                        <div>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Typography className={classes.cardHeaderTitleMobile} style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                                        Enter your store’s gross monthly processing amount ($) <span style={{ color: "#E53935" }}>*</span>
                                        {error.grossAmt ? (
                                            <span style={{ color: "red" }}>Required</span>
                                        ) : null}
                                    </Typography>
                                    <TextField
                                        className={classes.textFieldMobile}
                                        value={grossAmt}
                                        onChange={(e) => formatInput(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                ptsCalculator()
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                        }}
                                    />
                                    {edit ?
                                        <div style={{ marginTop: "20px" }}>
                                            <Typography className={classes.cardHeaderTitleMobile}>
                                                Enter your basis points amount <span style={{ color: "#E53935" }}>*</span>
                                                {error.basisAmt ? (
                                                    <span style={{ color: "red" }}>Required</span>
                                                ) : null}
                                            </Typography>
                                            <TextField
                                                // style={{ marginTop: "0" }}
                                                className={classes.textFieldMobile}
                                                value={basisPts}
                                                onChange={(e) => formatBasis(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        ptsCalculator()
                                                    }
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        input: classes.input
                                                    },
                                                }}
                                            />
                                        </div>
                                        : null}
                                </GridItem>

                                <GridItem xs={12} className={classes.gridPadding}>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Link to="/admin/user-page"target="_blank" rel="noopener noreferrer">
                                            <Button
                                                className={classes.goBackButtonMobile}
                                            >
                                                <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                                            </Button>
                                        </Link>

                                        <Button
                                            className={classes.submitButtonMobile}
                                            onClick={() => {
                                                ptsCalculator()
                                            }}
                                        >
                                            <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Submit
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={allRewards} style={{ padding: "5%", width: "300px", maxHeight: "450px", marginRight: "20px" }} />
                        </div>
                    </div>
                </div>
            </Hidden>


            <Hidden smDown implementation='css'>
                <GridContainer>
                    <GridItem xs={6} style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.headerTitle}>
                            View Calculator
                        </Typography>

                        {!edit ?
                            <>
                                <Button
                                    onClick={() => setEdit(true)}
                                    className={classes.editButton}
                                >
                                    <Edit style={{ marginRight: "10px", marginTop: "-5px" }} /> Edit
                                </Button>
                            </>
                            :
                            <Button
                                onClick={(e) => saveBasisPts(e)}
                                className={classes.editButton}
                                style={{ background: "#1A405F" }}
                            >
                                <SaveIcon style={{ marginRight: "10px", marginTop: "-5px" }} /> Save
                            </Button>
                        }



                        <CopyToClipboard text={`https://tuzo.app/auth/${userCompany.replace(/ /g, '').toLowerCase()}/iso-calculator`}
                            onCopy={() => setCopy(true)}>
                            <Button
                                // onClick={() => setGlobalEdit(true)}
                                style={{ background: "#56CA93", width: "200px" }}
                                className={classes.editButton}
                            >
                                <Copy style={{ marginRight: "10px", marginTop: "-5px" }} /> Copy Public URL
                            </Button>
                        </CopyToClipboard>
                        <Snackbar
                            open={copy}
                            onClose={() => setCopy(false)}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            autoHideDuration={3000}
                            style={{ top: "80px" }}
                        >
                            <Alert
                                onClose={() => setCopy(false)}
                                severity={"success"}
                                className={classes.alert}
                            >
                                Link Copied
                            </Alert>
                        </Snackbar>
                    </GridItem>
                </GridContainer>
                <div className={classes.calculatorCardIsoAdmin}>
                    <div>
                        <Typography className={classes.calculatorTitle}>
                            The Tuzo Rewards Calculator
                        </Typography>

                        <Typography className={classes.calculatorSubtitle}>
                            Calculate how many rewards points you can earn!
                        </Typography>
                    </div>
                    <div className={classes.calculatorCard}>
                        <div>
                            <Typography className={classes.cardHeaderTitle}>
                                Enter your store’s gross monthly processing amount ($) <span style={{ color: "#E53935" }}>*</span>
                                {error.grossAmt ? (
                                    <span style={{ color: "red" }}>Required</span>
                                ) : null}
                            </Typography>
                            <TextField
                                className={classes.textField}
                                value={grossAmt}
                                onChange={(e) => formatInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        ptsCalculator()
                                    }
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.input
                                    },
                                }}
                            />
                            {edit ?
                                <div style={{ marginTop: "20px" }}>
                                    <Typography className={classes.cardHeaderTitle}>
                                        Enter your basis points amount <span style={{ color: "#E53935" }}>*</span>
                                        {error.basisAmt ? (
                                            <span style={{ color: "red" }}>Please save before submitting</span>
                                        ) : null}
                                    </Typography>
                                    <TextField
                                        // style={{ marginTop: "0" }}
                                        className={classes.textField}
                                        value={basisPts}
                                        onChange={(e) => formatBasis(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                ptsCalculator()
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                input: classes.input
                                            },
                                        }}
                                    />
                                </div>
                                : null}
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <Link to="/admin/user-page"target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className={classes.goBackButton}
                                    >
                                        <ArrowBackIos style={{ marginRight: "10px", marginTop: "-3px" }} /> Go to App
                                    </Button>
                                </Link>
                                <Button
                                    className={classes.submitButton}
                                    onClick={() => {
                                        ptsCalculator()
                                    }}
                                >
                                    <CheckCircle style={{ marginRight: "10px", marginTop: "-5px" }} /> Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={allRewards} style={{ width: "450px", padding: "5%", maxHeight: "450px", marginRight: "65px" }} />
                        {userLogo ?
                            <img src={userLogo} style={{ width: "450px", padding: "5%", maxHeight: "450px", marginRight: "65px" }} />
                            : null}
                    </div>
                </div>
            </Hidden>
        </div>

    )

}

function mapStateToProps(state) {
    return {
        userLogo: state.auth.user.logo ? state.auth.user.logo : "",
        userCompany: state.auth.user.companyName,
        userId: state.auth.user.uid
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Calculator));