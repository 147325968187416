import React, { useEffect } from 'react';
import DonutChart from 'react-donut-chart';
import { CSVLink } from 'react-csv';
import moment from "moment"
// core components
import Box from '@material-ui/core/Box';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import actionsForNavigation from 'store/actions/navigation';
import AuthActions from 'store/actions/auth';
import { Checkbox, createMuiTheme, FormControl, Input, InputAdornment, InputLabel, ListItemText, makeStyles, MenuItem, OutlinedInput, Select, TextField, ThemeProvider, Typography } from '@material-ui/core';

// styling
import Style from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';


// @material-ui/icons
import { AiOutlineDollar } from 'react-icons/ai';
import { Add, Search } from '@material-ui/icons';
import { BsDownload } from 'react-icons/bs';
import { ReactComponent as CSVIcon } from "assets/img/tuzo/icons/CSVIcon.svg";

export function DashboardFilter(props) {
    const {
        classes,
        history,
        rewardsSwitch,
        setRewardsSwitch,
        statusDropdown,
        setStatusDropdown,
        ascDropdown,
        setAscDropdown,
        searchString,
        setSearchString,
        listSwitch,
        setListSwitch,
        statusArr,
        ascArr,
        handleRewardsToggle,
        handleListToggle,
        handleFilter,
        handleSearch,
        userTypeModal,
        setUserTypeModal,
        usersData,
        zippedArr,
        userTypeArr,
        handleUserTypeFilter,
        userTypeArrFilter,
        userHeaders,
        listHeaders,
        emptyData,
        resetEmptyData
    } = props;

    const numberFormatter = new Intl.NumberFormat('en-US', {
    });

    const capitalizeCell = (string) => {
        const words = string.split(" ");
        for (let i = 0; i < words.length; i++) {
            if (words[i].toUpperCase() !== "LLC") {
                if (i !== words.length - 1) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase() + " ";
                }
                else {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
                }
            }
        }
        return words
    }

    const fileNameUpdate = () => {
        if (userTypeArrFilter.length === 0 || userTypeArrFilter.length === 4) {
            return "All"
        }
        else if (userTypeArrFilter.length === 1) {
            return userTypeArrFilter
        }
        else {
            let newArr = []
            newArr.push(userTypeArrFilter.join('_'))
            return newArr
        }
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={4} style={{ marginBottom: "10px", display: "flex" }}>
                    {listSwitch === "Analytics" ?
                        <Typography
                            className={classes.dashboardTitle}
                        >
                            {usersData.length + " " + "Users "}
                        </Typography>
                        :
                        <Typography
                            className={classes.dashboardTitle}
                        >
                            {numberFormatter.format(zippedArr.length) + " " + "Reports"}
                        </Typography>
                    }


                    {userTypeArrFilter.length > 0?
                        <Typography
                            className={classes.dashboardTitle}
                            style={{ marginLeft: "10px" }}
                        >
                            {userTypeArrFilter.length < 4 ?
                                " " + zippedArr.length + " " + userTypeArrFilter
                                :
                                null
                            }
                        </Typography>
                        : null
                    }

                </GridItem>

                <GridItem xs={4} style={{ marginBottom: "10px" }}>
                    {listSwitch === "Analytics" ?
                        <div>
                            <FormControl style={{ margin: 1, width: "100%" }}>
                                <Select
                                    style={{ width: "100%" }}
                                    className={classes.selectMenuSort}
                                    value={userTypeArrFilter}
                                    disableUnderline
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    multiple
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <p className={classes.selectPlaceHolder}>User Type</p>;
                                        }
                                        return selected.join(', ');
                                    }}
                                    onChange={handleUserTypeFilter}
                                >
                                    <MenuItem
                                        disabled
                                        value=""
                                        classes={{
                                            root: classes.selectPlaceHolder,
                                        }}
                                    >
                                        <p
                                        >
                                            User Type
                                        </p>
                                    </MenuItem>
                                    {userTypeArr.map((o, i) => {
                                        return (
                                            <MenuItem
                                                key={o}
                                                value={o}
                                                classes={{
                                                    root: classes.selectText,
                                                }}
                                            >
                                                <Checkbox
                                                    style={{ color: "#1A405F" }}
                                                    checked={userTypeArrFilter.indexOf(o) > -1}
                                                />
                                                {o}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        :
                        null
                    }
                </GridItem>

                <GridItem xs={4} style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
                    {listSwitch === "Analytics" ?
                        <Button
                            className={classes.addButton}
                            style={{ width: "280px", marginTop: "-5px" }}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={userHeaders}
                                data={
                                    zippedArr.filter(d => d[0].demo === undefined || d[0].demo === false).map((d) => ({
                                        pts: d[0].approvedPts ? numberFormatter.format(d[0].approvedPts) : 0,
                                        name: d[0].firstName + " " + d[0].lastName,
                                        companyName: d[0].companyName,
                                        email: d[0].email,
                                        type: d[0].userType === "IsoAdmin" ? "ISO" : d[0].userType === "IsoMerchants" || d[0].userType === "Merchants" ? "Merchant" : d[0].userType === "Admin" || d[0].userType === "Administrator" ? "Admin" : d[0].userType,
                                        status: d[0].status ? d[0].status : "N/A",
                                        joined: d[0].timestamp ? moment.unix(d[0].timestamp.seconds).format("MM/DD/YY") : "N/A"
                                    }))
                                }
                                filename={`${fileNameUpdate()}_User_List`}

                            >
                                <BsDownload style={{ marginRight: "10px" }} /> Download User List

                            </CSVLink>
                        </Button>
                        :
                        <Button
                            className={classes.addButton}
                            style={{ width: "280px", marginTop: "-5px" }}
                        >
                            <CSVLink
                                style={{ color: "white" }}
                                headers={listHeaders}
                                data={
                                    zippedArr.filter(d => d[0].demo === undefined || d[0].demo === false).map((d) => ({
                                        pts: d[0].approvedPts ? numberFormatter.format(d[0].approvedPts) : 0,
                                        pendingPts: numberFormatter.format(d[1]),
                                        name: d[0].contact ? capitalizeCell(d[0].contact) : "N/A",
                                        email: d[0].email ? capitalizeCell(d[0].email) : "N/A",
                                        merchantNumber: d[0].merchantNumber ? d[0].merchantNumber : "N/A",
                                        companyName: d[0].merchantName ? capitalizeCell(d[0].merchantName) : "N/A",
                                        reportDate: moment(d[0].reportDate).format("MM/DD/YY"),
                                        userBasisPts: d[0].userBasisPts,
                                    }))
                                }
                                filename={`Reports_List`}
                            >
                                <BsDownload style={{ marginRight: "10px" }} /> Download Report

                            </CSVLink>
                        </Button>
                    }

                </GridItem>
                <GridItem xs={4}>
                    <div className={classes.toggleDiv}>
                        <div
                            onClick={() => {
                                handleRewardsToggle("All Users")
                                history.push('/admin/dashboard')
                            }}
                            style={
                                rewardsSwitch === "All Users"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopLeftRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            All Users
                        </div>
                        <div
                            value="Rewards"
                            onClick={() => {
                                handleRewardsToggle("Rewards")
                                history.push('/admin/rewards')
                            }}
                            style={
                                rewardsSwitch === "Rewards"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        // borderTopRightRadius: "4px",
                                        // borderBottomRightRadius: "4px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            Rewards
                        </div>
                        <div
                            value="Order History"
                            onClick={() => {
                                handleRewardsToggle("Order History")
                                history.push('/admin/orders')
                            }}
                            style={
                                rewardsSwitch === "Order History"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopLeftRadius: "4px",
                                        borderBottomLeftRadius: "4px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            Order History
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={6}>
                    <Select
                        className={classes.selectMenuSort}
                        value={statusDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onClick={(e) => {
                            if (e.target.value === 0 && statusDropdown === "User Type") {
                                setUserTypeModal(true)
                            }
                        }}
                        onChange={(e) => {
                            setStatusDropdown(e.target.value)
                            handleFilter(ascDropdown, e.target.value)
                            if (e.target.value === "User Type") {
                                setUserTypeModal(true)
                            }

                        }}
                    >
                        {statusArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        className={classes.selectAsc}
                        value={ascDropdown}
                        disableUnderline
                        classes={{
                            root: classes.selectText,
                        }}
                        onChange={(e) => {
                            setAscDropdown(e.target.value)
                            handleFilter(e.target.value, statusDropdown)

                        }}
                    >
                        {ascArr.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectText,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <TextField
                        className={classes.searchBar}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.input
                            },
                            endAdornment: (
                                !emptyData? 
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button
                                        className={classes.searchButton}
                                        onClick={() => handleSearch()}
                                    >
                                        <Search style={{ marginRight: "5px" }} />
                                        Search
                                    </Button>
                                </InputAdornment>
                                :
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button
                                        className={classes.searchButton}
                                        onClick={() => resetEmptyData()}
                                        style={{backgroundColor: "#06667C"}}
                                    >
                                        <Search style={{ marginRight: "5px" }} />
                                        Reset
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>

                <GridItem xs={2}>
                    <div className={classes.toggleDiv}>
                        <div
                            value="All Users"
                            onClick={handleListToggle}
                            style={
                                listSwitch === "List"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopLeftRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            List
                        </div>
                        <div
                            value="Rewards"
                            onClick={handleListToggle}
                            style={
                                listSwitch === "Analytics"
                                    ? {
                                        background: "#1A405F",
                                        border: "2px solid #1A405F",
                                        borderTopRightRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        color: "white"
                                    }
                                    : {}
                            }
                            className={classes.toggleSelection}
                        >
                            Analytics
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) =>
            dispatch(
                actionsForNavigation.openChild('Profile Editor', '/admin/user/editor')
            ),
        logout: () => dispatch(AuthActions.startLogout()),
    });
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DashboardFilter));
