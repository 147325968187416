/*eslint-disable*/
// @material-ui/icons
import Person from '@material-ui/icons/Person';

// routes
import Dashboard from 'views/Pages/dashboard/Dashboard.js';
import NewRewardsForm from 'views/Pages/adminrewards/NewRewardsForm';
import Travel from 'views/Pages/travel/Travel';
import Order from 'views/Pages/orders/Order';
import Activity from 'views/Pages/activity/Activity';
import NewProfile from 'views/Pages/profile/NewProfile';
import SalesByMerchant from 'views/Pages/reports/salesByMerchant/SalesByMerchant';
import BatchDetails from 'views/Pages/reports/adminBatchDetails/AdminBatchDetails';
import UserProfile from 'views/Pages/adminprofile/Profile';
import AdminRewardsPage from 'views/Pages/adminrewards/Rewards';
import ActionsPage from 'views/Pages/actions/actions';
import EditRewardsForm from 'views/Pages/adminrewards/EditRewardsForm';
import AddAd from 'views/Pages/ad/AddAds';
import Preview from 'views/Pages/ad/addAdcomponents/Preview';
import EditAd from 'views/Pages/ad/EditAd';
import Ads from 'views/Pages/ad/Ads';
import NewTravelRewardsForm from 'views/Pages/adminrewards/NewTravelRewardsForm';
import NewGiftCardForm from 'views/Pages/adminrewards/NewGiftCardForm';
import Orders from 'views/Pages/adminorders/Orders';
import EditGiftCardsRewardsForm from 'views/Pages/adminrewards/EditGiftCardsRewardsForm';
import EditTravelRewardsForm from 'views/Pages/adminrewards/EditTravelRewardsForm';
import Notifications from 'views/Pages/notifications/Notifications';
import MerchantProfile from 'views/Pages/adminuserprofile/UserProfile'
import AssignReward from 'views/Pages/assignreward/AssignReward';
import RewardsDetails from 'views/Pages/adminrewardsdetails/RewardsDetails';
import TempUserProfile  from 'views/Pages/admintempuserprofile/UserProfile';

var dashRoutes = [
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    layout: '/admin',
  },
  {
    path: '/user-profile/:id',
    name: 'Merchant Profile',
    component: MerchantProfile,
    layout: '/admin',
  },
  {
    path: '/temp-user-profile/:id',
    name: 'Merchant Profile',
    component: TempUserProfile,
    layout: '/admin',
  },
  {
    path: '/assign-reward',
    name: 'Assign Reward',
    component: AssignReward,
    layout: '/admin'
  },
  {
    path: '/reward-detail/:id',
    name: 'Rewards Detail',
    component: RewardsDetails,
    layout: '/admin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/activity',
    name: 'Activity',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Activity,
    layout: '/admin',
  },
  {
    path: '/rewards/edit-form/travel/:id',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: EditTravelRewardsForm,
    layout: '/admin',
  },
  {
    path: '/rewards/edit-form/gift-cards/:id',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: EditGiftCardsRewardsForm,
    layout: '/admin',
  },
  {
    path: '/rewards/edit-form/:id',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: EditRewardsForm,
    layout: '/admin',
  },
  {
    path: '/ads/preview/:id',
    name: 'Preview Promotional Ad',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Preview,
    layout: '/admin',
  },
  {
    path: '/ads/edit/:id',
    name: 'Edit Promotional Ad',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: EditAd,
    layout: '/admin',
  },
  {
    path: '/ads/create-ad',
    name: 'Add Promotional Ad',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: AddAd,
    layout: '/admin',
  },
  {
    path: '/ads',
    name: 'Ads List',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Ads,
    layout: '/admin',
  },
  {
    path: '/rewards/new-form/travel',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: NewTravelRewardsForm,
    layout: '/admin',
  },
  {
    path: '/rewards/new-form/gift-cards',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: NewGiftCardForm,
    layout: '/admin',
  },
  {
    path: '/rewards/new-form',
    name: 'Add Reward',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: NewRewardsForm,
    layout: '/admin',
  },
  {
    path: '/rewards',
    name: 'Rewards',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: AdminRewardsPage,
    layout: '/admin',
  },
  {
    path: '/travel',
    name: 'Travel',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Travel,
    layout: '/admin',
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: Orders,
    layout: '/admin',
  },
  {
    path: '/reports/batch/:id/:id',
    name: 'Batch Details',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: BatchDetails,
    layout: '/admin',
  },
  {
    path: '/reports/sales-by-merchant',
    name: 'Reports',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: SalesByMerchant,
    layout: '/admin',
  },
  {
    path: '/profile/add-user',
    name: 'New User Profile',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: NewProfile,
    layout: '/admin',
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/user-page',
    name: 'Actions',
    icon: Person,
    rtlName: 'ملف تعريفي للمستخدم',
    rtlMini: 'شع',
    component: ActionsPage,
    layout: '/admin',
  }
];
export default dashRoutes;
