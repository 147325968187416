/*eslint-disable*/
import React, { useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";

// mui core
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";

//components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// icons
import { ReactComponent as Upload } from "assets/img/tuzo/icons/Upload.svg";
import { ReactComponent as TrashIcon } from "assets/img/tuzo/icons/TrashIcon.svg";
import { ReactComponent as Edit } from "assets/img/tuzo/icons/EditIcon.svg";
import { ReactComponent as Add } from "assets/img/tuzo/icons/AddCircle.svg";
import { ReactComponent as CalendarIcon } from "assets/img/tuzo/icons/calendarIconWhite.svg";

//styling
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import avatar from "assets/img/default-avatar.png";
import loader from "assets/img/Tuzo-Preloader-Transparent.gif";
import "../newProfileCalendar.css";
import { BsDownload } from "react-icons/bs";

export function UserInfo(props) {
  const {
    classes,
    history,
    profilePic,
    setImageFile,
    imageFile,
    username,
    setUsername,
    apiUsername,
    setApiUsername,
    apiPassword,
    setApiPassword,
    firstNameState,
    setFirstNameState,
    lastNameState,
    setLastNameState,
    companyState,
    setCompanyState,
    birthdayState,
    setBirthdayState,
    emailState,
    setEmailState,
    cellPhoneState,
    setCellPhoneState,
    secondaryPhoneState,
    setSecondaryPhoneState,
    shippingAddressState,
    setShippingAddressState,
    globalEdit,
    handleImageUpload,
    handleDocUpload,
    deleteDoc,
    docFile,
    uploading,
    progress,
    deleteImage,
    formatPhoneNumber,
    userBasisPts,
    setUserBasisPts,
    merchantId,
    setMerchantId,
    type,
    setType,
    userTypeArr,
    whiteLabel,
    setWhiteLabel,
    signUpBonus,
    setSignUpBonus,
    processorName,
    setProcessorName,
    processorArr,
    handleDropImgUpload,
    brandingArr,
    errors,
  } = props;

  const disableDt = (current) => {
    return current.isBefore(moment());
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 1,
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: "10px" }}>
          <Typography
            variant="h2"
            className={classes.cardDesc}
            style={{ marginTop: "0px" }}
          >
            *Required
          </Typography>
        </GridItem>

        <GridItem xs={4}>
          <Card
            className={classes.profileCardStyling}
            style={{ overflow: "visible" }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Personal Details
                </Typography>
              </GridItem>

              {/* <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Typography variant="h2" className={classes.cardDesc}>
                                            User Type
                                        </Typography>
                                    </GridItem>

                                    <GridItem xs={6}>
                                        <Select
                                            style={{ paddingTop: "5px" }}
                                            className={classes.selectMenuSortProfile}
                                            value={type}
                                            disableUnderline
                                            classes={{
                                                root: classes.selectText,
                                            }}
                                            onChange={(e) => {
                                                setType(e.target.value)
                                                setWhiteLabel(false)
                                            }}
                                        >
                                            {userTypeArr.map((o, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        value={o}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                        }}
                                                    >
                                                        {o}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </GridItem>
                                </GridContainer>
                            </GridItem> */}

              {type === "ISO" ? (
                <>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Branding Level
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <Select
                            style={{ paddingTop: "5px" }}
                            className={classes.selectMenuSortProfile}
                            value={whiteLabel}
                            disableUnderline
                            classes={{
                                root: classes.selectText,
                            }}
                            onChange={(e) => {
                                setWhiteLabel(e.target.value)
                            }}
                        >
                            {brandingArr.map((o, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={o}
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        {o}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : type === "Merchant" ? (
                <>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography variant="h2" className={classes.cardDesc}>
                          Processor
                        </Typography>
                      </GridItem>
                      <GridItem xs={6}>
                        <Select
                          className={classes.selectMenuSortProfile}
                          value={processorName}
                          disableUnderline
                          classes={{
                            root: classes.selectText,
                          }}
                          onChange={(e) => {
                            setProcessorName(e.target.value);
                            setApiUsername("");
                            setApiPassword("");
                          }}
                        >
                          {processorArr.map((o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={o}
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                {o}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : null}

              {(type === "Merchant" &&
                processorName.includes("Fiserv AccessOne")) ||
              processorName.includes("Maverick") ? (
                <>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.apiUsername ? { marginTop: "30px" } : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          API Key/Username*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          className={classes.userInfoTextField}
                          value={apiUsername}
                          onChange={(e) => setApiUsername(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                        {errors.apiUsername ? (
                          <span className={classes.errText}>
                            Please fill out the field above
                          </span>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.apiPassword ? { marginTop: "30px" } : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          API Secret*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          className={classes.userInfoTextField}
                          value={apiPassword}
                          onChange={(e) => setApiPassword(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                        {errors.apiPassword ? (
                          <span className={classes.errText}>
                            Please fill out the field above
                          </span>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : type === "Merchant" &&
                processorName !== "Fiserv AccessOne" ? (
                <>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={
                            errors.apiUsername ? { marginTop: "30px" } : {}
                          }
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          API Key/Username*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          className={classes.userInfoTextField}
                          value={apiUsername}
                          onChange={(e) => setApiUsername(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                        {errors.apiUsername ? (
                          <span className={classes.errText}>
                            Please fill out the field above
                          </span>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : null}

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography
                      style={errors.username ? { marginTop: "30px" } : {}}
                      variant="h2"
                      className={classes.cardDesc}
                    >
                      Username*
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                    {errors.username ? (
                      <span className={classes.errText}>
                        Please fill out the field above
                      </span>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography
                      style={errors.firstName ? { marginTop: "30px" } : {}}
                      variant="h2"
                      className={classes.cardDesc}
                    >
                      First Name*
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={firstNameState}
                      onChange={(e) => setFirstNameState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                    {errors.firstName ? (
                      <span className={classes.errText}>
                        Please fill out the field above
                      </span>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography
                      style={errors.lastName ? { marginTop: "30px" } : {}}
                      variant="h2"
                      className={classes.cardDesc}
                    >
                      Last Name*
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={lastNameState}
                      onChange={(e) => setLastNameState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                    {errors.lastName ? (
                      <span className={classes.errText}>
                        Please fill out the field above
                      </span>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography
                      style={errors.company ? { marginTop: "30px" } : {}}
                      variant="h2"
                      className={classes.cardDesc}
                    >
                      Company*
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={companyState}
                      onChange={(e) => setCompanyState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                    {errors.company ? (
                      <span className={classes.errText}>
                        Please fill out the field above
                      </span>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Birthday
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <Datetime
                      className="drop-down"
                      isValidDate={disableDt}
                      selected={birthdayState}
                      dateFormat="MM/DD/YY"
                      onChange={(date, e) => {
                        setBirthdayState(date.format("MM/DD/YY"));
                      }}
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside
                      renderInput={(props, openCalendar, closeCalendar) => (
                        <div onClick={openCalendar}>
                          <TextField
                            style={{ paddingLeft: "0px" }}
                            className={classes.userInfoTextField}
                            value={birthdayState}
                            variant="outlined"
                            InputProps={{
                              classes: {
                                adornedEnd: classes.adournedEnd,
                                input: classes.inputDatetime,
                              },
                              endAdornment: (
                                <InputAdornment
                                  className={classes.adournedEnd}
                                  position="end"
                                >
                                  <Button className={classes.datetimeButton}>
                                    <CalendarIcon
                                      className={classes.calendarIcon}
                                      onClick={openCalendar}
                                      style={{ width: "15px", height: "15px" }}
                                    />
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              {type === "Merchant" ? (
                <>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={errors.points ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          User Basis Points*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          type="number"
                          className={classes.userInfoTextField}
                          value={userBasisPts}
                          onChange={(e) => setUserBasisPts(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                            inputProps: {
                              min: 0,
                              max: 50,
                            },
                          }}
                        />
                        {errors.points ? (
                          <span className={classes.errText}>
                            Please fill out the field above
                          </span>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <Typography
                          style={errors.id ? { marginTop: "30px" } : {}}
                          variant="h2"
                          className={classes.cardDesc}
                        >
                          Merchant ID*
                        </Typography>
                      </GridItem>

                      <GridItem xs={6}>
                        <TextField
                          className={classes.userInfoTextField}
                          value={merchantId}
                          onChange={(e) => setMerchantId(e.target.value)}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              adornedEnd: classes.adournedEnd,
                              input: classes.input,
                            },
                          }}
                        />
                        {errors.id ? (
                          <span className={classes.errText}>
                            Please fill out the field above
                          </span>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </>
              ) : null}
            </GridContainer>
          </Card>
        </GridItem>

        {type === "Merchant" || type === "Administrator" ? (
          <>
            <GridItem xs={4}>
              <Card
                className={classes.profileCardStyling}
                style={{ height: "100%" }}
              >
                <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h2" className={classes.cardHeader}>
                      Profile Picture
                    </Typography>
                  </GridItem>

                  <GridItem xs={4} style={{ marginTop: "20px" }}>
                    {imageFile ? (
                      <div>
                        <img className={classes.img} src={imageFile} />
                      </div>
                    ) : (
                      <div className={classes.noProfilePic}>NU</div>
                    )}
                  </GridItem>

                  <GridItem xs={8}>
                    <Typography
                      variant="h2"
                      className={classes.cardDesc}
                      style={{ marginTop: "20px" }}
                    >
                      Upload your photo (optional)
                    </Typography>

                    <Typography
                      variant="h2"
                      className={classes.cardDescLessWeight}
                    >
                      Ideal profile images are cropped as a square and should be
                      at least 300px x 300px.
                    </Typography>

                    <div style={{ display: "flex" }}>
                      <Button
                        className={classes.uploadButton}
                        component="label"
                      >
                        <Upload style={{ marginRight: "10px" }} /> Upload
                        <input
                          type="file"
                          hidden
                          onChange={(event) => {
                            handleImageUpload(event, "modal");
                          }}
                        />
                      </Button>

                      <Button
                        className={classes.uploadButton}
                        style={{ background: "#56CA93" }}
                        onClick={() => deleteImage()}
                      >
                        <TrashIcon style={{ marginRight: "10px" }} /> Delete
                      </Button>
                    </div>

                    {uploading ? (
                      <GridContainer
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        <GridItem xs={10}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "80%", mr: 1 }}>
                              <LinearProgress
                                variant="determinate"
                                value={Math.round(progress) * 100}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <h6
                                style={{
                                  color: "#1A405F",
                                  fontSize: "22px",
                                  fontFamily: "Josefin Sans",
                                }}
                              >
                                {Math.round(progress)}%
                              </h6>
                            </Box>
                          </Box>
                        </GridItem>
                        <GridItem xs={2}>
                          <img
                            style={{ width: "20%" }}
                            src={loader}
                            alt="..."
                          />
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </>
        ) : type === "ISO" ? (
          <>
            <GridItem xs={4}>
              <Card
                className={classes.profileCardStyling}
                style={{ height: "100%" }}
              >
                <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h2" className={classes.cardHeader}>
                      Company Logo
                    </Typography>
                  </GridItem>

                  <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <div>
                      <Dropzone
                        disabled={whiteLabel === "Default" ? true : false}
                        max={104857600}
                        multiple={false}
                        open
                        noClick={
                          imageFile.length === 0 || !imageFile ? true : false
                        }
                        accept=".jpeg, .png, .jpg"
                        onDrop={(acceptedFiles) => {
                          var array = [];
                          Array.from(acceptedFiles).forEach((file) => {
                            array = [...array, file];
                          });
                          handleDropImgUpload(array);
                        }}
                      >
                        {({ getRootProps, getInputProps, open }) => (
                          <div
                            {...getRootProps()}
                            className={classes.imgSquare}
                          >
                            <input {...getInputProps()} />
                            {imageFile.length === 0 || !imageFile ? (
                              <div style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "18px",
                                  }}
                                >
                                  <BsDownload
                                    style={{
                                      color: "#56CA93",
                                      fontSize: "50px",
                                      marginBottom: "-10px",
                                    }}
                                  />
                                  {"  "}
                                </p>
                                <p className={classes.cardDescWeight500}>
                                  Drop logo image here or click “Upload” below
                                </p>
                              </div>
                            ) : (
                              <img
                                // key={i}
                                style={{
                                  height: "100px",
                                  width: "150px",
                                  marginTop: "20px",
                                  objectFit: "contain",
                                }}
                                src={imageFile}
                              />
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </GridItem>

                  <GridItem xs={12}>
                    <Typography
                      variant="h2"
                      className={classes.cardDescWeight500}
                    >
                      Suggested image dimensions: 250 by 50 pixels
                    </Typography>
                  </GridItem>
                  <GridItem
                    xs={12}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className={classes.uploadButtonIso}
                      component="label"
                      disabled={whiteLabel === "Default" ? true : false}
                    >
                      <Upload style={{ marginRight: "10px" }} /> Upload
                      <input
                        type="file"
                        hidden
                        onChange={(event) => {
                          handleImageUpload(event, "modal");
                        }}
                      />
                    </Button>

                    <Button
                      className={classes.uploadButtonIso}
                      style={{ background: "#56CA93" }}
                      disabled={whiteLabel === "Default" ? true : false}
                      onClick={() => deleteImage()}
                    >
                      <TrashIcon style={{ marginRight: "10px" }} /> Delete
                    </Button>
                    {uploading ? (
                      <GridContainer
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "15px",
                        }}
                      >
                        <GridItem xs={10}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "80%", mr: 1 }}>
                              <LinearProgress
                                variant="determinate"
                                value={Math.round(progress) * 100}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <h6
                                style={{
                                  color: "#1A405F",
                                  fontSize: "22px",
                                  fontFamily: "Josefin Sans",
                                }}
                              >
                                {Math.round(progress)}%
                              </h6>
                            </Box>
                          </Box>
                        </GridItem>
                        <GridItem xs={2}>
                          <img
                            style={{ width: "20%" }}
                            src={loader}
                            alt="..."
                          />
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </>
        ) : null}

        <GridItem xs={4}>
          <Card className={classes.profileCardStyling}>
            <GridContainer>
              <GridItem xs={12}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Contact Information
                </Typography>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography
                      style={
                        errors.email || errors.emailTypo
                          ? { marginTop: "30px" }
                          : {}
                      }
                      variant="h2"
                      className={classes.cardDesc}
                    >
                      Email*
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={emailState}
                      onChange={(e) => setEmailState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                    {errors.email ? (
                      <span className={classes.errText}>
                        Please fill out the field above
                      </span>
                    ) : errors.emailTypo ? (
                      <span className={classes.errText}>
                        Please double check your email
                      </span>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Phone Number
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={cellPhoneState}
                      onChange={(e) => setCellPhoneState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Secondary Phone
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextField
                      className={classes.userInfoTextField}
                      value={secondaryPhoneState}
                      onChange={(e) => setSecondaryPhoneState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Typography variant="h2" className={classes.cardDesc}>
                      Shipping Address
                    </Typography>
                  </GridItem>

                  <GridItem xs={6}>
                    <TextareaAutosize
                      style={{
                        height: "66px",
                        color: "#1A405F",
                        lineHeight: "24px",
                      }}
                      className={classes.userInfoTextField}
                      value={shippingAddressState}
                      onChange={(e) => setShippingAddressState(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          adornedEnd: classes.adournedEnd,
                          input: classes.input,
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>

        {/* {type === "ISO" ?
                    <GridItem xs={6}>
                        <Card className={classes.profileCardStyling} style={{ marginTop: "20px", height: "auto" }}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Typography variant="h2" className={classes.cardHeader}>
                                        Contract Details
                                    </Typography>
                                </GridItem>

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={5}>
                                            <Typography
                                                style={errors.points ? { marginTop: "30px" } : {}}
                                                variant="h2"
                                                className={classes.cardDesc}>
                                                Basis Points Set Aside
                                            </Typography>
                                        </GridItem>

                                        <GridItem xs={7}>
                                            <TextField
                                                type="number"
                                                className={classes.userInfoTextField}
                                                value={userBasisPts}
                                                onChange={(e) => setUserBasisPts(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                    inputProps: {
                                                        min: 0,
                                                        max: 50
                                                    }
                                                }}
                                            />
                                            {errors.points ? (
                                                <span className={classes.errText}>Please fill out the field above</span>
                                            ) : null}
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={5}>
                                            <Typography variant="h2" className={classes.cardDesc}>
                                                Sign-Up Bonus
                                            </Typography>
                                        </GridItem>

                                        <GridItem xs={7}>
                                            <TextField
                                                type="number"
                                                className={classes.userInfoTextField}
                                                value={signUpBonus}
                                                onChange={(e) => setSignUpBonus(e.target.value)}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: {
                                                        adornedEnd: classes.adournedEnd,
                                                        input: classes.input
                                                    },
                                                    inputProps: {
                                                        min: 0,
                                                        max: 50
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={5}>
                                            <Typography variant="h2" className={classes.cardDesc}>
                                                Contract
                                            </Typography>
                                        </GridItem>

                                        <GridItem xs={7}>
                                            <Typography variant="h2" className={classes.cardDescLessWeight}>
                                                {"N/A"}
                                            </Typography>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                    :
                    null
                } */}
        <GridItem xs={12}>
          <Card
            className={classes.profileCardStyling}
            style={{ marginTop: "20px", height: "auto" }}
          >
            <GridContainer>
              <GridItem xs={5}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Upload Documents
                </Typography>
              </GridItem>
              <GridItem xs={7}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Documents
                </Typography>
                <GridItem
                  xs={12}
                  style={{
                    borderBottom: "1px solid #56CA93",
                    margin: "6px 5px",
                  }}
                />
              </GridItem>

              <GridItem xs={5}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    style={{ marginTop: "20px", textAlign: "center" }}
                  >
                    <div className={classes.uploadDocument}>
                      <GridItem xs={12}>
                        <Typography
                          variant="h2"
                          className={classes.cardDesc}
                          style={{ marginTop: "20px" }}
                        >
                          Upload your Document (optional)
                        </Typography>

                        <Typography
                          variant="h2"
                          className={classes.cardDescLessWeight}
                        >
                          Supported File Types: PDF, JPEG, PNG, EPS, DOCX
                        </Typography>
                        {uploading ? (
                          <GridContainer
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "15px",
                            }}
                          >
                            <GridItem xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ width: "100%", mr: 1 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={Math.round(progress) * 100}
                                  />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                  <h6
                                    style={{
                                      color: "#1A405F",
                                      fontSize: "22px",
                                      fontFamily: "Josefin Sans",
                                    }}
                                  >
                                    {Math.round(progress)}%
                                  </h6>
                                </Box>
                              </Box>
                            </GridItem>
                          </GridContainer>
                        ) : null}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className={classes.uploadButton}
                            component="label"
                          >
                            <Upload style={{ marginRight: "10px" }} /> Upload
                            <input
                              type="file"
                              hidden
                              onChange={(event) => {
                                handleDocUpload(event, "modal");
                              }}
                            />
                          </Button>
                        </div>
                      </GridItem>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={7}>
                <GridContainer>
                  {docFile.map((file, index) => (
                    <GridItem xs={12} key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h2"
                          className={classes.contractDesc}
                          style={{ marginRight: "10px" }}
                        >
                          {file.fileName}
                        </Typography>
                        <GridItem
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            className={classes.uploadButton}
                            style={{ background: "#56CA93", width: 12 }}
                            onClick={() =>
                              deleteDoc(file.fileName, file.filePath)
                            }
                          >
                            <TrashIcon />
                          </Button>
                        </GridItem>
                      </div>
                    </GridItem>
                  ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(profileStyle)
  )(UserInfo)
);
