import {
  whiteColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = (theme) => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  fullPage: {
    display: "flex",
    justifyContent: "center",
    // padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    zIndex: 1,
    backgroundColor: "#fff",
    color: whiteColor,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100%",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
    "&:before,&:after": {
      // display: "block",
      // // content: '""',
      // position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2",
      backgroundColor: "#fff",
    },
  },
  fullPage2: {
    display: "flex",
    justifyContent: "center",
    // padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    margin: "0",
    border: "0",
    zIndex: 1,
    backgroundColor: "#1A405F",
    color: whiteColor,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    // height: "100%",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
    "&:before,&:after": {
      // display: "block",
      // // content: '""',
      // position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2",
      backgroundColor: "#fff",
    },
  },
  logoAndText: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    right: "40%",
    top: "2%",
    [theme.breakpoints.down("sm")]: {
      right: "15px",
      top: "80px",
    },
  },
  logoImg: {
    width: "46vw",
    position: "absolute",
    top: "0%",
    left: "-56px",
    [theme.breakpoints.up("1440")]: {
      top: "0%",
    },
    [theme.breakpoints.up("xl")]: {
      top: "7% !important",
    },
  },
  logoImgMobile: {
    width: "86vw",
    position: "absolute",
    top: "-14%",
    left: "47px",
    [theme.breakpoints.down("380")]: {
      top: "-16%",
      left: "97px",
      width: "60vw",
    },
    [theme.breakpoints.down("380")]: {
      top: "-10%",
      left: "97px",
      width: "60vw",
    },
  },
  logoImgMobileRegister: {
    width: "86vw",
    position: "absolute",
    top: "-14%",
    left: "47px",
    [theme.breakpoints.down("380")]: {
      top: "-16%",
      left: "97px",
      width: "60vw",
    },
    [theme.breakpoints.down("380")]: {
      top: "-15%",
      left: "97px",
      width: "60vw",
    },
  },
});

export default pagesStyle;
