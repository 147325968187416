// src/components/CustomInput/CustomChipInput.js

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReactChipInput from "react-chip-input"; // Use react-chip-input
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(styles);

export default function CustomChipInput(props) {
  const classes = useStyles();

  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    chips, // Added chips prop
    onAdd, // Added onAdd prop
    onDelete, // Added onDelete prop
    ...rest
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses = formControlProps
    ? classNames(formControlProps.className, classes.formControl)
    : classes.formControl;

  const helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText && (
        <InputLabel
          className={`${classes.labelRoot} ${labelClasses}`}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      )}
      <ReactChipInput
        value={chips}
        onAdd={(chip) => onAdd(chip)}
        onDelete={(chip, index) => onDelete(chip, index)}
        InputProps={{
          ...inputProps,
          classes: {
            input: classNames(inputClasses, underlineClasses),
          },
          disableUnderline: true, // To manage underline via classes
          // Optionally, you can pass additional props here
        }}
        textFieldProps={{
          placeholder: "Add a chip",
          variant: "outlined",
          // You can pass more TextField props if needed
        }}
        {...rest}
      />
      {helperText && (
        <FormHelperText id={`${id}-text`} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

CustomChipInput.propTypes = {
  chips: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  formControlProps: PropTypes.object,
  labelText: PropTypes.node,
  id: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  white: PropTypes.bool,
  inputRootCustomClasses: PropTypes.string,
  success: PropTypes.bool,
  helperText: PropTypes.node,
};
