/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { db, firebase, storage } from "firebase/client";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import AuthActions from "store/actions/auth";
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

// core components
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Checkbox, IconButton } from "@material-ui/core";
import Loader from "components/Loader/Loader";

// @material-ui/icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

// styling
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import tuzoLogo from "assets/img/tuzo/tuzoHorizontal.png";
import { lowerCase } from "lodash";

export function LoginPage(props) {
  const isoCompany = window.location.pathname.split("/")[2];
  const { classes, authError, history } = props;
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordNotice, setPasswordNotice] = useState(null);
  const [checked, setChecked] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [logo, setLogo] = useState(tuzoLogo);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    let decodeIso = isoCompany.replace(/-/g,'').toLowerCase();
    if (!dataLoaded) {
      db.collection("users")
        .get()
        .then((snapshots) => {
          if (!snapshots.empty) {
            snapshots.forEach((doc) => {
              let data = doc.data();
              let lowerCaseCompany = "";
              if (data.companyName) {
                lowerCaseCompany = data.companyName.replace(/['-.]/g,'').replace(/ /g,'').toLowerCase();
              }
              if (lowerCaseCompany === decodeIso) {
                if (data.logo) {
                  setLogo(data.logo);
                }
              }
            });
          }
        })
        .then(() => {
          setDataLoaded(true);
        });
    }
  }, []);

  if (!dataLoaded) return <Loader />;

  return (
    <div style={{ overflow: "auto" }}>
      <Hidden mdUp implementation="css">
        <GridContainer>
          <GridItem xs={12} className={classes.gridItemFlexCenter}>
            <img className={classes.logoImg} src={logo} />
          </GridItem>

          <GridItem xs={12} className={classes.gridItemFlexCenter}>
            <Card className={classes.mobileIsoMainContainer}>
              <CardHeader className={classes.cardHeaderTitle}>
                Log In
              </CardHeader>
              <CardBody className={classes.cardBodyMobile}>
                {authError ? (
                  <div className={classes.textDanger}>
                    {" "}
                    <p>
                      <b style={{ color: "red", paddingTop: "8px" }}>Error:</b>{" "}
                      {authError.message}
                    </p>
                  </div>
                ) : null}
                <CustomInput
                  needWhite
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{
                    style: {
                      fontWeight: 600,
                      size: "17px",
                      color: "#004253",
                      fontFamily: "Josefin Sans",
                    },
                  }}
                  inputProps={{
                    onChange: (e) => setEmailInput(e.target.value),
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        props.emailSignIn({
                          email: emailInput,
                          password: passwordInput,
                        });
                      }
                    },
                    disableUnderline: true,
                    form: {
                      autocomplete: "off",
                    },
                    style: {
                      border: "2px solid rgba(15, 55, 87, 0.6)",
                      borderRadius: "5px",
                      height: "50px",
                    },
                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <Email />
                    //   </InputAdornment>
                    // )
                  }}
                />
                <CustomInput
                  needWhite
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{
                    style: {
                      fontWeight: 600,
                      size: "17px",
                      color: "#004253",
                      fontFamily: "Josefin Sans",
                    },
                  }}
                  inputProps={{
                    onChange: (e) => setPasswordInput(e.target.value),
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        props.emailSignIn({
                          email: emailInput,
                          password: passwordInput,
                        });
                      }
                    },
                    type: "password",
                    disableUnderline: true,
                    form: {
                      autocomplete: "off",
                    },
                    style: {
                      border: "2px solid rgba(15, 55, 87, 0.6)",
                      borderRadius: "5px",
                      height: "50px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPw(!showPw)}>
                          {!showPw ? (
                            <Visibility style={{ color: "#929292" }} />
                          ) : (
                            <VisibilityOff style={{ color: "#929292" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    padding: "4% 0%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      onClick={() => {
                        setChecked(!checked);
                      }}
                      checked={checked}
                      style={{ color: "#929292", marginLeft: "-10px" }}
                    />
                    <span className={classes.rememberMeMobile}>
                      Remember Me
                    </span>
                  </div>

                  <span
                    className={classes.forgotYourPasswordMobile}
                    onClick={() => history.push("/auth/forget-password")}
                  >
                    Forgot Your Password?
                  </span>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={() => {
                      props.emailSignIn({
                        email: emailInput,
                        password: passwordInput,
                      });
                    }}
                    className={classes.continueButtonMobile}
                    style={{ backgroundColor: "#1A405F", color: "#FFFFFF" }}
                    type="submit"
                    color="primary"
                    simple
                    size="lg"
                    block
                  >
                    Log In
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <div className={classes.linkContainerMobile}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <span
                  className={classes.isoTermsStyle}
                  onClick={() =>
                    window.open(
                      "https://www.tuzorewards.com/terms-of-use/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Terms of Use
                </span>
                <span
                  className={classes.isoTermsStyle}
                  onClick={() =>
                    window.open(
                      "https://www.tuzorewards.com/privacy-policy/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Privacy Policy
                </span>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </Hidden>

      <Hidden smDown implementation="css">
        <GridContainer>
          <GridItem xs={12} className={classes.gridItemFlexCenter}>
            <img className={classes.logoImg} src={logo} />
          </GridItem>

          <GridItem
            xs={12}
            className={classes.gridItemFlexCenter}
            style={{ marginTop: "10px" }}
          >
            <Card className={classes.isoMainContainer}>
              <CardHeader className={classes.cardHeaderTitle}>
                Log In
              </CardHeader>
              <CardBody className={classes.cardBody}>
                {authError ? (
                  <div className={classes.textDanger}>
                    {" "}
                    <p>
                      <b style={{ color: "red", paddingTop: "8px" }}>Error:</b>{" "}
                      {authError.message}
                    </p>
                  </div>
                ) : null}
                <CustomInput
                  needWhite
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setEmailInput(e.target.value),
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        props.emailSignIn({
                          email: emailInput,
                          password: passwordInput,
                        });
                      }
                    },
                    disableUnderline: true,
                    form: {
                      autocomplete: "off",
                    },
                    style: {
                      border: "2px solid #929292",
                      borderRadius: "5px",
                      height: "50px",
                    },
                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <Email />
                    //   </InputAdornment>
                    // )
                  }}
                  labelProps={{
                    style: {
                      fontWeight: 600,
                      size: "17px",
                      color: "#004253",
                      fontFamily: "Josefin Sans",
                    },
                  }}
                />
                <CustomInput
                  needWhite
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setPasswordInput(e.target.value),
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        props.emailSignIn({
                          email: emailInput,
                          password: passwordInput,
                        });
                      }
                    },
                    type: !showPw ? "password" : "text",
                    disableUnderline: true,
                    form: {
                      autocomplete: "off",
                    },
                    style: {
                      border: "2px solid #929292",
                      borderRadius: "5px",
                      height: "50px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPw(!showPw)}>
                          {!showPw ? (
                            <Visibility style={{ color: "#929292" }} />
                          ) : (
                            <VisibilityOff style={{ color: "#929292" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  labelProps={{
                    style: {
                      fontWeight: 600,
                      size: "17px",
                      color: "#004253",
                      fontFamily: "Josefin Sans",
                    },
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Checkbox
                      onClick={() => {
                        setChecked(!checked);
                      }}
                      checked={checked}
                      style={{ color: "#929292", marginLeft: "-10px" }}
                    />
                    <span className={classes.rememberMe}>Remember Me</span>
                  </div>

                  <span
                    className={classes.forgotYourPassword}
                    onClick={() => history.push("/auth/forget-password")}
                  >
                    Forgot Your Password?
                  </span>
                </div>
                {authError ? (
                  <div className={classes.justifyContentCenter}>
                    <p className={classes.textDanger}>
                      {/* <br /><b>Error:</b> {authError.message}<br /><br /> */}
                      <b>Did you forget your password?</b>
                    </p>
                    <Button
                      style={{ width: "30%", height: "42px" }}
                      className={classes.resetPwButton}
                      onClick={() => history.push("/auth/forget-password")}
                    >
                      Reset Password
                    </Button>
                  </div>
                ) : null}
                {passwordNotice ? (
                  <p className={classes.textDanger}>{passwordNotice}</p>
                ) : null}
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                <Button
                  onClick={() => {
                    props.emailSignIn({
                      email: emailInput,
                      password: passwordInput,
                    });
                  }}
                  className={classes.continueButton}
                  style={{
                    backgroundColor: "#1A405F",
                    color: "#FFFFFF",
                    width: "100% !important",
                  }}
                  type="submit"
                  color="primary"
                  simple
                  size="lg"
                  block
                >
                  Log In
                </Button>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <span
                  className={classes.isoTermsStyle}
                  onClick={() =>
                    window.open(
                      "https://www.tuzorewards.com/terms-of-use/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Terms of Use
                </span>
              </div>

              <div>
                <span
                  className={classes.isoTermsStyle}
                  onClick={() =>
                    window.open(
                      "https://www.tuzorewards.com/privacy-policy/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Privacy Policy
                </span>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authError: state.auth.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    emailSignIn: (credentials) =>
      dispatch(
        handleError(
          chain(
            AuthActions.startEmailLogin(credentials),
            push("/admin/user-page")
          ),
          AuthActions.setError
        )
      ),
    passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
  };
}

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle)
  )(LoginPage)
);
